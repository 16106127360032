import React from 'react';
import * as DesignSystem from 'src/components/design-system';
import routes from 'src/config/routes';
import { useAxiosNow } from 'src/hooks/axios/useAxios';

const capitalize = (s) => `${s.charAt(0).toUpperCase()}${s.slice(1)}`;
const getLines = (inscriptions) => {
  inscriptions.sort((a, b) => {
    const c = a.enfant.prenom.localeCompare(b.enfant.prenom);
    if (c !== 0) return c;
    return a.enfant.nom.localeCompare(b.enfant.nom);
  });

  return inscriptions.map((i) => [
    capitalize(i.enfant.prenom),
    i.enfant.nom.toUpperCase(),
    i.enfant.vaccination === 'non' ? 'À vérifier' : 'OK',
    i.certificat_medical_fourni === 0 ? 'À vérifier' : 'OK',
    i.famille.is_membreCA ? 'Oui' : '',
    <input type="checkbox" />,
  ]);
};

const Presence = ({ creneaux }) => (
  <div className="mx-auto">
    {creneaux.map((creneau) => {
      return (
        <div style={{ pageBreakAfter: 'always' }}>
          <DesignSystem.H2 className="w-full mx-auto text-center mb-4">
            {creneau.ref} – {creneau.jour_seul} à {creneau.heure_debut}
          </DesignSystem.H2>
          <DesignSystem.Table
            className="mb-8 print:w-full"
            headers={[
              'Prénom',
              'Nom',
              'Vaccination',
              'Certificat médical',
              'Membre CA',
              'Présence',
            ]}
            lines={getLines(creneau.inscriptions)}
          ></DesignSystem.Table>
        </div>
      );
    })}
  </div>
);

const PresenceWithData = () => {
  const { requestState } = useAxiosNow({
    url: routes.presence(),
  });

  return (
    <DesignSystem.Loading requestState={requestState}>
      {(rs) => {
        const { data } = rs;
        return <Presence creneaux={data.creneaux} />;
      }}
    </DesignSystem.Loading>
  );
};

export default PresenceWithData;
