import React from 'react';

import AdminGuard from 'src/components/admin/guard';

import Presence from 'src/components/admin/famille/presence';

const PresencePage = ({ location }) => (
  <AdminGuard>{({ userProfile }) => <Presence />}</AdminGuard>
);

export default PresencePage;
